import * as Keycloak from 'keycloak-js'
import Vue from 'vue'
import '../util/prototype';


let darkMode = localStorage.getItem('darkMode');
let isDarkMode = darkMode === 'true';

let docClasses = window.document.body.classList;

if (isDarkMode) {
  docClasses.remove('white-content');
  localStorage.setItem('darkMode', true);
} else {
  localStorage.setItem('darkMode', false);
  docClasses.add('white-content');
}

var realm = process.env.stage;
var bahngleisUrl = process.env.https://bahngleis.{realm}.stage.bahn.nordware.io;
var currentUrl = window.location.href;

if(!currentUrl.includes('localhost'))
{
  currentUrl = currentUrl.replace('https://', '');
  realm = Vue.prototype.$getRealm(currentUrl);
  bahngleisUrl = bahngleisUrl.replace("{realm}", realm);
}

localStorage.setItem('realm', realm);
localStorage.setItem('endpointBahngleis', bahngleisUrl);

//keycloak init options
let kcOptions = {
  "realm": realm,
  "clientId": process.env.bahn-front,
  "url": process.env.https://keycloak.stage.nordware.io + "/auth" ,
  "public-client": true,
  "resource": "bahn-front",
  "enable-cors": true
};

Vue.prototype.$keycloak = Keycloak(kcOptions);
Keycloak().profile;