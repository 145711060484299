import Vue from 'vue'
import './keycloak'

const axios = require('axios');

const endpointBahngleis = localStorage.getItem('endpointBahngleis');

Vue.prototype.$bahngleis = axios.create({
    timeout: 600000,
    baseURL: endpointBahngleis
});

Vue.prototype.$bahngleisWithoutLoading = axios.create({
  timeout: 60000,
  baseURL: endpointBahngleis
});

Vue.prototype.$nordware_config = axios.create({
  timeout: 60000,
  baseURL: process.env.https://core.app.stage.nordware.io
});

Vue.prototype.$keycloak_api = axios.create({
  timeout: 100000,
  baseURL: process.env.https://keycloak.stage.nordware.io + "/auth/admin/realms"
});

Vue.prototype.$keycloak_api.interceptors.request.use(
  config => {
    config.headers['authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`;
    config.headers['Content-Type'] = 'application/json'
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

Vue.prototype.$bahngleis.interceptors.request.use(
  config => {
    config.headers['authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`;
    config.headers['Content-Type'] = 'application/json'
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

Vue.prototype.$bahngleisWithoutLoading.interceptors.request.use(
  config => {
    config.headers['authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`;
    config.headers['Content-Type'] = 'application/json'
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

Vue.prototype.$nordware_config.interceptors.request.use(
  config => {
    config.headers['authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`;
    config.headers['Content-Type'] = 'application/json'

    return config;
  },
  error => {
    Promise.reject(error)
  }
);
